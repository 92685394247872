import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Grid, Divider, CircularProgress } from '@mui/material';
import { Phone, Room, WatchLater, DoneOutline } from '@mui/icons-material';

import { store } from 'js/core/configureStore';
import GoogleMaps from './GoogleMaps';

import { toast } from 'react-toastify';

import {
  placeIsOpen,
  trackEventMatomo,
  getDistanceFromLatLonInKm,
  colorText,
} from 'js/library/utils/helpers';

import registerVists from 'js/library/utils/API/registerVisits';
import { theme } from 'index';

export default function PlaceAbout({ checkAnonymous, userInfo, establishmentInfo }) {
  const { t } = useTranslation();

  const isAnonymous =
    userInfo === null || userInfo.triiboId.indexOf('@anonymous-triibo,com,br') >= 0;

  const [showDescription, setShowDescription] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasVisitToday, setHasVisitToday] = useState(false);

  const handleRegisterVisits = async () => {
    if (isAnonymous) {
      checkAnonymous();
      return;
    }

    const user = store.getState().codeInputModel.userInfo;
    const visitType = 'favorito';

    setLoading(true);

    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const distante = getDistanceFromLatLonInKm(
          position.coords.latitude,
          position.coords.longitude,
          establishmentInfo.lat,
          establishmentInfo.long
        );

        if (distante > 1) {
          setLoading(false);
          toast.warning(t('general.looks_like_you_are_not_at_the_property'));
          return;
        }

        if (hasVisitToday) {
          setLoading(false);
          toast.warning(t('general.your_visit_has_already_been_registered'));
          return;
        }

        if (!placeIsOpen(establishmentInfo.funcionamento)) {
          setLoading(false);
          toast.warning(t('general.establishment_currently_closed'));
          return;
        }

        try {
          await registerVists(user.uId, establishmentInfo.id, visitType);
          toast.success(t('general.visit_successfully_registered'));
          setLoading(false);
          setHasVisitToday(true);
        } catch (error) {
          if (error.response.status) {
            setLoading(false);
            toast.warning(t('general.visit_successfully_registered'));
            return;
          }
          toast.error(t('errors.try_later'));
          setLoading(false);
        }
      },
      (error) => {
        if (error.code === 1) {
          setLoading(false);
          toast.warning(t('general.give_access_to_your_localization_to_register_visit'));
          return;
        }

        toast.error(t('errors.try_later'));
        setLoading(false);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 1000 }
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0.8rem',
      }}
    >
      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
        <i>{t('general.about')}</i>
      </Typography>
      <Typography variant="subtitle1">
        <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
          {showDescription
            ? establishmentInfo.descricao
              ? establishmentInfo.descricao.substring(0, 100) + '...'
              : ''
            : establishmentInfo.descricao}
        </Typography>
      </Typography>
      <span style={{ cursor: 'pointer' }} onClick={() => setShowDescription(!showDescription)}>
        {showDescription ? t('general.show_more') : t('general.show_less')}
      </span>

      {establishmentInfo.endereco === 'Brasil' ? null : (
        <div className="contentMaps">
          <GoogleMaps establishmentInfo={establishmentInfo} />
        </div>
      )}

      <div
        style={{
          lineHeight: 'initial',
          marginTop: 15,
          padding: '0 16px',
          fontSize: 15,
        }}
      >
        <Grid item sm={7} xs={12} style={{ margin: '20px 0px' }}>
          {establishmentInfo.endereco !== undefined && establishmentInfo.endereco !== 'Brasil' ? (
            <div
              style={{
                margin: '10px 0',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ width: '15%', paddingRight: 10, float: 'left' }}>
                {/* <img alt="endereço" src={iconPin} style={{ maxWidth: '100%', display: 'block' }} /> */}
                <Room
                  style={{
                    fontSize: '2.5rem',
                    maxWidth: '100%',
                    display: 'block',
                    color: '#000000',
                  }}
                />
              </div>
              <div style={{ width: '85%', float: 'left' }}>{establishmentInfo.endereco}</div>
            </div>
          ) : null}
          {establishmentInfo.contatos !== undefined ? (
            <div
              style={{
                margin: '10px 0',
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <div style={{ width: '15%', paddingRight: 10, float: 'left' }}>
                <Phone
                  style={{
                    fontSize: '2.5rem',
                    maxWidth: '100%',
                    display: 'block',
                    color: '#000000',
                  }}
                />
              </div>
              <div style={{ width: '85%', float: 'left' }}>
                {establishmentInfo.contatos.map((contato, index) => (
                  <span key={index} style={{ display: 'block' }}>
                    {contato}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </Grid>

        <Grid
          container
          spacing={4}
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <Grid item sm={12} xs={12}>
            {!placeIsOpen(establishmentInfo.funcionamento) ? (
              <Typography variant="subtitle1" style={{ marginBottom: 10, color: 'ef4022' }}>
                <WatchLater
                  style={{
                    verticalAlign: 'middle',
                    color: '#000000',
                    fontSize: '2rem',
                  }}
                />{' '}
                <i>{t('general.closed')}</i>
              </Typography>
            ) : (
              <Typography variant="subtitle1" style={{ marginBottom: 10, color: 'green' }}>
                <WatchLater
                  style={{
                    verticalAlign: 'middle',
                    color: '#000000',
                    fontSize: '2rem',
                  }}
                />{' '}
                <i>{t('general.now_open')}</i>
              </Typography>
            )}

            {establishmentInfo.funcionamento !== undefined &&
            establishmentInfo.endereco !== 'Brasil'
              ? establishmentInfo.funcionamento.map(function (array, i) {
                  return (
                    <ul
                      key={i}
                      style={{
                        width: '100%',
                        padding: 0,
                        margin: '0 auto',
                        listStyle: 'none',
                      }}
                    >
                      <li key={i}>
                        <span style={{ float: 'left', width: '50%' }}>
                          {t('days').split('_')[i]}
                        </span>
                        <span
                          style={{
                            float: 'right',
                            width: '50%',
                            textAlign: 'right',
                          }}
                        >
                          {array.ativo
                            ? array.horarioInicio + '-' + array.horarioFim
                            : t('general.closed')}
                        </span>
                      </li>
                      <Divider style={{ margin: '10px 0px', float: 'right' }} width="100%" />
                    </ul>
                  );
                })
              : null}
          </Grid>
        </Grid>

        {/* EM BREVE */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',
            marginTop: '30px',
          }}
        >
          {loading ? (
            <CircularProgress
              style={{ fontSize: '16px', color: colorText(theme.palette.primary.main) }}
            />
          ) : (
            <div
              onClick={() => {
                handleRegisterVisits();
                trackEventMatomo('Estabelecimento', 'tocar', 'botao', 'Registre sua visita');
              }}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {establishmentInfo.endereco === 'Brasil' ||
              establishmentInfo.endereco === '' ? null : (
                <>
                  <div
                    style={{
                      width: '50px',
                      height: '50px',
                      borderRadius: '50%',
                      background: theme.palette.primary.main,
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <DoneOutline
                      style={{ color: colorText(theme.palette.primary.main), size: '18px' }}
                    />
                  </div>
                  <span style={{ fontSize: '0.7rem', fontWeight: '400' }}>
                    {t('general.register_your_visit')}
                  </span>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

