import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Paper } from '@mui/material';
import placeholderItem from 'styles/assets/placeholder/placeholder.gif';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { trackEventMatomoElementId } from 'js/library/utils/helpers';

export const BalanceItem = (voucher) => {
  const [thumbnail, setThumbnail] = React.useState(placeholderItem);

  voucher = voucher.voucher;

  function downloadImageOnLoad() {
    if (voucher.thumbnail !== undefined) {
      try {
        downloadImage('voucher', voucher.thumbnail)
          .then((downloaded) => {
            setThumbnail(downloaded);
          })
          .catch((error) => {
            return error;
          });
      } catch (e) {
        console.log(e);
      }
    }
  }
  const descricao =
    voucher.description === undefined
      ? ''
      : voucher.description.length < 115
      ? voucher.description
      : voucher.description.substring(0, 70) + '...';

  return (
    <Link
      className="swiper-slide"
      onClick={() => trackEventMatomoElementId('Carteira', 'toque', 'cupom', voucher?.key)}
      to="/carteira/detalhes"
      state={voucher}
      style={{
        height: '100%',
        textDecoration: 'none',
      }}
    >
      <Paper sx={{ padding: '10px', height: '100%' }}>
        <img
          style={{ width: '100%', height: 'auto' }}
          src={thumbnail}
          alt="carteira"
          onLoad={() => thumbnail === placeholderItem && downloadImageOnLoad()}
        />
        <div>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">
            {voucher.title}
          </Typography>

          <Typography variant="body1">{voucher.establishmentName}</Typography>

          <Typography variant="caption">{descricao}</Typography>
        </div>
      </Paper>
    </Link>
  );
};
export default BalanceItem;
