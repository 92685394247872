import React, { useEffect, useState } from 'react';
import { Tabs, Tab, CircularProgress } from '@mui/material';
import { NotificationDisplayContainer, NotificationNoneFound } from './styles';

import NotificationBody from './NotificationBody';
import FeedsBody from './FeedsBody';
import {
  trackEventMatomo,
  colorText,
  trackEventMatomoElementId,
} from '../../library/utils/helpers';

const NotificationDisplay = (props) => {
  const {
    isOpen,
    promotionList,
    setPromotionList,
    feedPosts,
    colorPrimary,
    colorSecondary,
    notificationDisplayRef,
    notificationLoading,
  } = props;
  const [tabIndex, setTabIndex] = useState(0);
  const [notReadListLength, setNotReadListLength] = useState('');

  let timeDebounce = null;

  function scrollMatomo(location, element, elementName) {
    clearTimeout(timeDebounce);
    timeDebounce = setTimeout(() => {
      trackEventMatomo(location, 'scroll', element, elementName);
    }, 400);
  }

  const tabProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  };

  const getLengthOfNotRead = (arr) => {
    let newArr = [];

    for (let x = 0; x < arr.length; x++) {
      if (arr[x].hasOwnProperty('lida') === false) {
        newArr.push(arr[x]);
      }
    }

    return newArr.length;
  };

  const handleTabs = async (event, newValue) => {
    trackEventMatomo('menu', 'click', 'tabs-sininho', newValue === 0 ? 'novidades' : 'mensagens');
    setTabIndex(newValue);
  };

  useEffect(() => {
    const lengthNotReadNotifications = getLengthOfNotRead(promotionList);
    setNotReadListLength(lengthNotReadNotifications > 0 ? ` (${lengthNotReadNotifications})` : '');
  }, [promotionList]);

  useEffect(() => {
    if (notificationLoading)
      notificationDisplayRef.current.scrollTop = notificationDisplayRef.current.scrollHeight;
  }, [notificationLoading, notificationDisplayRef]);

  return (
    <NotificationDisplayContainer tabIndex={tabIndex} $isOpen={isOpen}>
      <Tabs
        value={tabIndex}
        onChange={handleTabs}
        sx={{
          width: '100%',
          root: {
            color: colorSecondary,
            background: colorPrimary,
          },
          indicator: {
            backgroundColor: colorSecondary,
          },
        }}
      >
        <Tab label="Novidades" style={{ color: colorText(colorPrimary) }} {...tabProps(0)} />
        <Tab
          label={`Mensagens${notReadListLength}`}
          style={{ color: colorText(colorPrimary) }}
          {...tabProps(1)}
        />
      </Tabs>
      {tabIndex === 0 && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
          ref={notificationDisplayRef}
          onScroll={() => scrollMatomo('menu', 'notification', 'feed')} 
        >
          {feedPosts.length === 0 ? (
            <NotificationNoneFound>Nenhuma novidade encontrada.</NotificationNoneFound>
          ) : (
            feedPosts.map(({ action, appFilter, id, image, title, message, sender }) => (
              <FeedsBody
                key={`feed-${id}`}
                action={action}
                appFilter={appFilter}
                id={id}
                image={image}
                title={title}
                message={message}
                sender={sender}
                colorPrimary={colorPrimary}
                colorSecondary={colorSecondary}
                onScroll={() => scrollMatomo('menu', 'notification', 'messages')}
              />
            ))
          )}
          {notificationLoading && (
            <CircularProgress style={{ color: colorText(colorPrimary) }} size="3rem" />
          )}
        </div>
      )}
      {tabIndex === 1 && (
        <div
          id="notifications"
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            overflowY: 'scroll',
          }}
        >
          {promotionList.length === 0 ? (
            <NotificationNoneFound>Nenhuma mensagem encontrada.</NotificationNoneFound>
          ) : (
            promotionList.map(({ titulo, mensagem, data, tipo, url, id, tableId, lida }, index) => (
              <NotificationBody
                key={`notificacao-${index}`}
                title={titulo}
                description={mensagem}
                isCoupon={tipo === 'promotion' ? true : false}
                date={data}
                activity={promotionList[index].hasOwnProperty('lida') ? false : true}
                url={url}
                id={id}
                tableId={tableId}
                lida={lida}
                tabIndex={tabIndex}
                setPromotionList={setPromotionList}
                onClick={() =>
                  trackEventMatomoElementId(
                    document.title,
                    'click',
                    'notificacao oferta',
                    'item: ' + id
                  )
                }
              />
            ))
          )}
        </div>
      )}
    </NotificationDisplayContainer>
  );
};

export default NotificationDisplay;
