import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';
import { CircularProgress } from '@mui/material';
import {
  colorText,
  convertToTimestamp,
  isValidDate,
  randomizeNumbersString,
  trackEventMatomo,
  trackEventMatomoVisit,
} from 'js/library/utils/helpers.js';
import Input from 'js/components/DesignSystem/Input';
import Checkbox from 'js/components/DesignSystem/Checkbox';

import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';
import axios from 'axios';
import { toast } from 'react-toastify';
import { SignUpContext } from 'js/context/SignUpContext';
import { useNavigate } from 'react-router-dom';
import { FormControl, MenuItem, Select } from '@mui/material';

const UFs = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

export default function CreateUser() {
  const [formValues, setFormValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [formFeldsFilter, setFormFeldsFilter] = useState([]);
  const [countryCode, setCountryCode] = useState('+55');
  const [dataCellPhone, setDataCellPhone] = useState({
    cellPhone: '',
    currentDDD: countryCode,
    inputMask: '',
    fullMask: '',
    currentCountry: '',
  });
  const { formFields, setFormData, accessToken } = useContext(SignUpContext);
  const navigate = useNavigate();
  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  useEffect(() => {
    if (dataCellPhone.currentDDD) {
      setCountryCode(dataCellPhone.currentDDD);
    }
  }, [dataCellPhone.currentDDD]);

  useEffect(() => {
    if (formValues?.cep?.length === 9) {
      const formatedCEP = formValues?.cep?.replace('-', '');
      axios
        .get(`https://viacep.com.br/ws/${formatedCEP}/json/`)
        .then((response) => {
          if (response.data.erro) {
            toast.error('CEP não encontrado!');
            setFormValues((prevFormValues) => ({
              ...prevFormValues,
              cep: '',
            }));
          }
          const data = response.data;
          setFormValues((prevFormValues) => ({
            ...prevFormValues,
            city: data.localidade,
            neighborhood: data.bairro,
            state: data.uf,
            streetAve: data.logradouro,
          }));
        })
        .catch((error) => {
          console.log('CEP não encontrado.', error);
          toast.error('Insira um CEP válido!');
        });
    }
  }, [formValues.cep]);

  const handleChange = (event) => {
    setFormValues({ ...formValues, state: event.target.value });
  };

  const treatmentCep = (e) => {
    const inputValue = e.target.value;
    let sanitizedValue = inputValue.replace(/\D/g, '');
    if (sanitizedValue.length >= 9) {
      return;
    }

    if (sanitizedValue.length > 5) {
      sanitizedValue = sanitizedValue.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      cep: sanitizedValue,
    }));
  };

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setDataCellPhone({
      cellPhone: '',
      currentDDD: valuesArr[0],
      inputMask: valuesArr[1],
      fullMask: valuesArr[2],
      currentCountry: e.target.value,
    });
  };

  const handleInputChange = (event, input) => {
    const name = event.target.name;
    let value = event.target.value;

    if (input.inputType === 'number' && isNaN(value)) {
      setLoading(false);
      return toast.warn(`O campo ${input.label} deve conter apenas números.`);
    }
    let formattedValue = '';
    if (input.inputType === 'date') {
      value = value.replace(/\D/g, '');
      if (value.length > 0) {
        formattedValue = value.substring(0, 2);
      }
      if (value.length > 2) {
        formattedValue += '/' + value.substring(2, 4);
      }
      if (value.length > 4) {
        formattedValue += '/' + value.substring(4, 8);
      }
    }

    if (name === 'zipCode' && value.length > 5) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{5})(\d{1,3})/, '$1-$2');
    }

    if (input.inputType === 'cellPhone') {
      value = value.replace(/\D/g, '');

      setFormValues({
        ...formValues,
        [name]: {
          value: value,
        },
      });
    } else if (input.type === 'optInList') {
      setFormValues({
        ...formValues,
        [name]: {
          accept: event.target.checked,
        },
      });
    } else if (input.inputType === 'checkbox') {
      value = event.target.checked;
      setFormValues({
        ...formValues,
        [name]: {
          value: value,
        },
      });
    } else {
      setFormValues({
        ...formValues,
        [name]: {
          value: input.inputType === 'date' ? formattedValue : value,
        },
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let cellPhoneInvalid = false;
    let userInfoObj = {};

    let contactList = [];
    let documentList = [];
    let optInList = [];
    let addressList = [];

    if (
      formFeldsFilter.type === 'addressList' &&
      (!formValues.cep || !formValues.streetAve || !formValues.city || !formValues.state)
    ) {
      return toast.warn('Endereço incompleto.');
    }

    const filterFields = formFields.filter((field) => field.fieldName !== 'cellPhone');

    let stopFunction = false;

    filterFields.forEach((field, index) => {
      if (field.fieldType === 'date') {
        if (!isValidDate(formValues[field.fieldName]?.value)) {
          setLoading(false);
          toast.warn('Por favor, insira uma data válida!');
          stopFunction = true;
        }
        userInfoObj[field.fieldName] = convertToTimestamp(formValues[field.fieldName]?.value);
      }
      if (
        ((formValues[field.fieldName] || formValues[`optIn_${field.optInId}`]) &&
          (formValues[field.fieldName]?.value || formValues[`optIn_${field.optInId}`]?.accept)) ||
        field.type === 'addressList' ||
        !!field.indexKey
      ) {
        switch (field.type) {
          case 'contactList':
            if (field.indexKey && !!accessToken) {
              contactList.push({
                type: field.fieldName,
                value: accessToken,
              });
            } else if (field.fieldType === 'cellPhone') {
              const formatCellPhone = formValues[field.fieldName].value.replace(/\D/g, '');

              if (formValues[field.fieldName].value.length < 11) {
                cellPhoneInvalid = true;
              }

              contactList.push({
                type: field.fieldName,
                value: `${dataCellPhone.currentDDD}${formatCellPhone}`,
              });
            } else if (field.fieldType === 'email') {
              contactList.push({
                type: field.fieldName,
                value: formValues[field.fieldName]?.value,
              });
            }

            userInfoObj.contactList = contactList;
            break;
          case 'documentList':
            if (field.indexKey && !!accessToken) {
              documentList.push({
                type: field.fieldName,
                value: accessToken,
              });
              userInfoObj.documentList = documentList;
              break;
            }

            if (field.fieldType === 'cpf' || field.fieldType === 'cnpj') {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName]?.value?.replaceAll(/\D/g, ''),
              });
              userInfoObj.documentList = documentList;
              break;
            }

            documentList.push({
              type: field.fieldName,
              value: formValues[field.fieldName].value,
            });

            userInfoObj.documentList = documentList;
            break;

          case 'addressList':
            addressList.push({
              zipCode: formValues.cep,
              city: formValues.city,
              extra: formValues.complement,
              streetAve: formValues.streetAve,
              streetNumber: formValues.streetNumber,
              state: formValues.state,
              neighborhood: formValues.neighborhood,
            });
            userInfoObj.addressList = addressList;
            break;
          case 'optInList': {
            const optIn = `optIn_${field.optInId}`;

            if (formValues[optIn]) {
              optInList.push({
                accept: formValues[optIn].accept,
                dateAcceptance: Date.now(),
                optInId: field.optInId,
                type: field.label,
                version: 1,
              });
              userInfoObj.optInList = optInList;
            }
            break;
          }

          case 'others':
            if (formValues[field.fieldName]) {
              if (field.fieldType === 'date') {
                userInfoObj[field.fieldName] = convertToTimestamp(
                  formValues[field.fieldName]?.value
                );
              } else if (field.fieldType === 'cellPhone') {
                const cellPhoneValue = formValues[field.fieldName]?.value.replaceAll(/\D/g, '');

                const minLength = 11;
                const isCellPhoneValid = cellPhoneValue.length >= minLength;

                if (!isCellPhoneValid) {
                  cellPhoneInvalid = true;
                }

                userInfoObj[field.fieldName] = cellPhoneValue;
              } else {
                userInfoObj[field.fieldName] = formValues[field.fieldName]?.value;
              }
            } else if (field.indexKey) {
              userInfoObj[field.fieldName] = accessToken;
            }
            break;

          case 'root':
            if (field.fieldType === 'date') {
              userInfoObj[field.fieldName] = convertToTimestamp(formValues[field.fieldName]?.value);
            } else {
              userInfoObj[field.fieldName] = formValues[field.fieldName]?.value;
            }
            break;
          default:
            userInfoObj[field.fieldName] = formValues[field.fieldName]?.value;
            break;
        }
      }
    });

    if (stopFunction) return;

    if (cellPhoneInvalid) {
      setLoading(false);
      return toast.warn('Número de celular inválido.');
    }

    userInfoObj.partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;
    userInfoObj.partnerName = seuClubePersist.clubeId;

    setFormData(userInfoObj);
    navigate('/validarCelular');
  };

  const renderInput = (input, index) => {
    const validationsInput = {
      aliasName: '12',
    };
    switch (input.inputType) {
      case 'checkbox':
        return (
          <Checkbox
            label={input.required ? input.label + '*' : input.label}
            checked={
              formValues[input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName]
                ? formValues[
                    input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName
                  ]?.value
                : false
            }
            handleChange={(e) => handleInputChange(e, input)}
            id={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
            name={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
            required={input.required}
          />
        );
      case 'date':
        return (
          <Input
            // type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={formValues[input.fieldName] && formValues[input.fieldName]?.value}
            onChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
          />
        );

      case 'number':
        return (
          <Input
            type={input.inputType}
            label={input.required ? input.label + '*' : input.label}
            value={formValues[input.fieldName] && formValues[input.fieldName]?.value}
            onChange={(e) => handleInputChange(e, input)}
            id={input.fieldName}
            name={input.fieldName}
            required={input.required}
          />
        );
      case 'cellPhone':
        return (
          <S.InputContainer>
            <label>{input.required ? input.label + '*' : input.label}</label>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                gap: '1rem',
                border: ' 1px solid #afafaf',
                borderRadius: '4px',
                height: '42px',
                width: '100%',
              }}
            >
              <select
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  height: '100%',
                  border: 'none',
                  background: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  cursor: 'pointer',
                }}
                value={dataCellPhone.currentCountry}
                onChange={handleCountryChange}
              >
                {phoneMaskArray.map((country, index) => (
                  <option
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.emoji} {country.initials} {country.ddd}
                  </option>
                ))}
              </select>

              <S.Input
                cellphone
                label={input.required ? input.label + '*' : input.label}
                type={input.inputType}
                value={formValues[input.fieldName] && formValues[input.fieldName]?.value}
                onChange={(e) => handleInputChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                placeholder={randomizeNumbersString(input.inputMask)}
                mask={
                  formValues[input.fieldName]?.value === '' || !formValues[input.fieldName]?.value
                    ? ''
                    : input.inputMask
                }
              />
            </div>
          </S.InputContainer>
        );
      case 'address':
        return (
          <S.ContainerAddress>
            {<strong>{input.required ? input.label + '*' : input.label}:</strong>}
            {formValues?.cep?.length !== 9 ? (
              <Input
                value={formValues.cep}
                label={input.required ? 'CEP:*' : 'CEP:'}
                onChange={treatmentCep}
                id="cep"
                name="cep"
                required
                maxlength={9}
              />
            ) : (
              <>
                <Input
                  value={formValues.cep}
                  label={input.required ? 'Cep do ' + input.label + '*' : 'Cep do ' + input.label}
                  onChange={treatmentCep}
                  id="cep"
                  name="cep"
                  required
                  maxlength={9}
                />
                <S.ContentInfoAddress>
                  <Input
                    value={formValues.streetAve}
                    disabled={formValues.streetAve ? true : false}
                    label="Rua*"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        streetAve: e.target.value,
                      })
                    }
                    id="streetAve"
                    name="streetAve"
                    required
                  />

                  <Input
                    value={formValues.streetNumber}
                    label="Número*"
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        streetNumber: e.target.value,
                      })
                    }
                    id="streetNumber"
                    name="streetNumber"
                    required
                  />
                </S.ContentInfoAddress>
                <S.ContentInfoAddress>
                  <Input
                    value={formValues.neighborhood}
                    label="Bairro*"
                    disabled={formValues.neighborhood ? true : false}
                    handleChange={(e) =>
                      setFormValues({
                        ...formValues,
                        neighborhood: e.target.value,
                      })
                    }
                    id="neighborhood"
                    name="neighborhood"
                    required
                  />
                  <Input
                    value={formValues.city}
                    disabled={formValues.city ? true : false}
                    label="Cidade*"
                    onChange={(e) => setFormValues({ ...formValues, city: e.target.value })}
                    id="city"
                    name="city"
                    required
                  />

                  <FormControl
                    sx={{
                      display: 'flex',
                      height: '71px',
                      justifyContent: 'space-between',
                    }}
                    fullWidth
                  >
                    <label>UF*</label>
                    <Select
                      id="uf-select"
                      value={formValues.state || ''}
                      disabled={formValues.state ? true : false}
                      onChange={handleChange}
                      required
                      sx={{
                        height: '42px',
                        width: '100%',
                        borderRadius: '10px',
                        padding: '0.7rem',
                        outline: 'none',
                      }}
                    >
                      {UFs.map((uf) => (
                        <MenuItem key={uf} value={uf}>
                          {uf}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </S.ContentInfoAddress>
                <Input
                  value={formValues.complement}
                  label="Complemento"
                  onChange={(e) => setFormValues({ ...formValues, complement: e.target.value })}
                  id="complement"
                  name="complement"
                />
              </>
            )}
          </S.ContainerAddress>
        );
      default:
        return (
          <S.InputContainer>
            <label>{input.required ? input.label + '*' : input.label}</label>
            <S.Input
              mask={
                formValues[input.fieldName]?.value === '' || !formValues[input.fieldName]?.value
                  ? ''
                  : input.inputMask
              }
              type={input.inputType}
              label={input.required ? input.label + '*' : input.label}
              value={formValues[input.fieldName] && formValues[input.fieldName]?.value}
              onChange={(e) => handleInputChange(e, input)}
              id={input.fieldName}
              name={input.fieldName}
              required={input.required}
              maxLength={validationsInput[input.fieldName] || Infinity}
            />
          </S.InputContainer>
        );
    }
  };

  useEffect(() => {
    if (formFields.length > 0) {
      const formFeldsFilterFilter = formFields.filter(
        (field) => field.fieldName !== 'cellPhone' && !field.indexKey
      );

      setFormFeldsFilter(formFeldsFilterFilter);
    }
  }, [formFields]);

  useEffect(() => {
    trackEventMatomoVisit('cadastroUsuario');
  }, []);

  return (
    <S.Container className="paper-container">
      <h1>Cadastre-se</h1>
      <S.Form onSubmit={handleSubmit}>
        {formFeldsFilter.map((field, index) => (
          <div key={index}>
            {renderInput(field, index)}
            <br />
            {field.type === 'optInList' && (
              <a href={field.link} target="_blank" rel="noreferrer">
                Ver termos de Uso
              </a>
            )}
          </div>
        ))}

        <S.Button
          onClick={() => trackEventMatomo('CadastroUsuario', 'tocar', 'button', 'Cadastrar')}
          type="submit"
          bgColor={seuClubePersist.colorPrimary}
          style={{ color: colorText(seuClubePersist.colorPrimary) }}
          disabled={loading}
        >
          {loading ? (
            <CircularProgress
              style={{ color: colorText(seuClubePersist.colorPrimary) }}
              size={20}
            />
          ) : (
            'Cadastrar'
          )}
        </S.Button>
      </S.Form>
    </S.Container>
  );
}
