import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import * as S from '../../components/BellNotification/styles';

import { AppBar, IconButton, Grid, Menu, List, ListItem, Drawer, Hidden } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';

import { LoggedRoutes } from 'js/containers/Routes/LoggedRoutes';
import { WhatsappButton } from 'js/components/WhatsappButtonFixed/WhatsappButton';

import Footer from 'js/containers/Footer/Footer';
import Loading from 'js/containers/Loading/Loading';
import LoginModal from 'js/containers/Login/LoginModal';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import { logout, checkUserStatus } from 'js/library/services/AuthenticationManager.js';
import { colorText, getLastUserInfo, trackEventMatomo } from 'js/library/utils/helpers';

import { getBalance_v1 } from 'js/library/utils/API/getBalance_v1.js';
import { useTranslation } from 'react-i18next';
import createAnonymous from 'js/library/utils/API/apiCreateAnonymous';
import { SignUpContext } from 'js/context/SignUpContext';
import {
  ButtonLogin,
  CenterColumn,
  ContainerTopBar,
  DisplayFlex,
  HoverBar,
  IconBtn,
  LeftBanner,
  LeftColumn,
  Line,
  LinkHtml,
  MenuItemList,
  MenuMobile,
  MenuWeb,
  PageInactiveClube,
  PointArea,
  PointAreaMobile,
  RightColumn,
  UserContainer,
  UserContent,
  UserContentLoggedout,
} from './drawerStyle';
import NotificationDisplay from 'js/components/BellNotification/NotificationDisplay';
import BellNotificationMenu from 'js/components/BellNotification/BellNotificationMenu';
import markAsRead from 'js/library/utils/API/markAsRead';
import getFeedPosts from 'js/library/utils/API/getFeedPosts';
import getAllMessagesFromUser from 'js/library/utils/API/getAllMessagesFromUser';
import NPSModal from 'js/components/NPSModal';

import { Divider } from '@mui/material';
import {
  HouseLine,
  List as MenuIcon,
  Wallet,
  ChatText,
  SignIn,
  Files,
  Headset,
  SignOut,
  User,
} from 'phosphor-react';
import { UploadUserPhotos } from './UploadUserPhotos/UploadUserPhotos';
export default function PrimarySearchAppBar() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpened, setDrawerOpened] = React.useState(false);
  const [authenticated, setAuthenticated] = React.useState(false);
  // const [points, setPoints] = React.useState(0);
  const [openState, setOpenState] = React.useState(false);
  const [isOpenModalUserPhoto, setIsOpenModalUserPhoto] = React.useState(false);

  const [notificationIsOpen, setNotificationIsOpen] = useState(false);
  const [promotionList, setPromotionList] = useState([]);
  const [feedPosts, setFeedPosts] = useState([]);
  const [lastCreationDate, setLastCreationDate] = useState(0);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [isModalBottomAlsmotReached, setIsModalBottomAlsmotReached] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  // const [color, setColor] = useState('#000000');
  const [reload, setReload] = useState(false);

  const location = useLocation();
  const isMenuOpen = Boolean(anchorEl);
  const userInfo = getLastUserInfo();
  const { checkUser, infosSeuClube, points, setPoints } = useContext(SignUpContext);
  const today = new Date();
  const fullwidthPages = ['confirmar-dados', 'landing-page'];

  const [keywords, setKeywords] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [colorPrimary, setColorPrimary] = useState('');
  const [colorSecondary, setColorSecondary] = useState('');

  // const [isAnonymous, setIsAnonymous] = useState(false);
  let isAnonymous = userInfo !== null;
  isAnonymous = isAnonymous ? userInfo.triiboId?.indexOf('@anonymous-triibo,com,br') >= 0 : false;

  const limit = 5;

  useEffect(() => {
    setReload(!reload);
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (infosSeuClube) {
      setKeywords(infosSeuClube.keywords);
      setOrgs(infosSeuClube.orgs);
      setColorPrimary(infosSeuClube.colorPrimary);
      setColorSecondary(infosSeuClube.colorSecondary);
    }
  }, [infosSeuClube]);

  useEffect(() => {
    checkUserStatus()
      .then(async (response) => {
        if (userInfo === null || response === null) {
          logout();
          const user = await createAnonymous(infosSeuClube.partnerId);
          setAuthenticated(user);
        } else {
          if (!isAnonymous) {
            const balanceReceived = await getBalance_v1(userInfo.uId, userInfo.triiboId);

            setPoints(
              balanceReceived.consolidateBalance && balanceReceived.consolidateBalance.total
            );
          }
          setAuthenticated(response);
        }
      })
      .catch(async (error) => {
        const user = await createAnonymous();
        setAuthenticated(user);
      });
    // eslint-disable-next-line
  }, [checkUser]);

  const closeModalUserPhoto = () => {
    setIsOpenModalUserPhoto(false);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    trackEventMatomo('Menu', 'tocar', 'botao', 'menu hamburguer');
  };

  const handleNotificationCloser = (isOpen) => {
    setNotificationIsOpen(!isOpen);
    if (isOpen) {
      Promise.all(
        promotionList
          .filter((item) => !item.lida) // Filtra apenas os itens não lidos
          .map((item) => markAsRead(userInfo.uId, item.tableId, userInfo.triiboId))
      );
    }
  };

  useEffect(() => {
    if (!userInfo || (userInfo.isAnonymous && !userInfo.anonData)) {
      //USUÁRIO DESLOGADO E SEM ANÔNIMO, CRIAR USUÁRIO ANÔNIMO E SETAR NO REDUX
      createAnonymous().then((result) => {
        const payload = {
          anonData: result,
          triiboId: result.triiboId.replace(/[.]/gi, ','),
          isAnonymous: true,
          uId: result.uId,
        };
        localStorage.setItem('userInfoAuxiliar', JSON.stringify(payload));
      });
    } else if (userInfo && !userInfo.triiboId.includes('anonymous')) {
      //USUÁRIO LOGADO, CHAMADAS DE APIS PARA TRAZER AS ÚLTIMAS NOTIFICAÇÕES E FEED DE POSTS
      getAllMessagesFromUser(userInfo.uId, 'system', true).then((result) => {
        setPromotionList(result);
      });
      if (keywords.length > 0 || orgs.length > 0) {
        getFeedPosts(userInfo.uId, keywords, orgs, [], today.getTime(), limit).then((data) => {
          setFeedPosts(data.result);
          const lastItemIndex = data.result.length - 1;
          setLastCreationDate(
            data?.result[lastItemIndex]?.notification
              ? data?.result[lastItemIndex].creationDate
              : data?.result[lastItemIndex]?.publishDate
          );
        });
      }
    }
    // eslint-disable-next-line
  }, [keywords, orgs, authenticated]);

  //FAVICON DINÂMICO
  useEffect(() => {
    (function dynamicFavicon() {
      const favicon = document.getElementById('favicon');

      if (infosSeuClube !== null) {
        favicon.href = cfac22('STORAGE_URL') + 'seuClube%2F' + infosSeuClube.favicon + '?alt=media';
      }
    })();
  }, [infosSeuClube]);

  const infinityScrollNotification = (ref) => {
    const handleScroll = () => {
      if (!ref?.current) return;

      const { clientHeight, scrollHeight, scrollTop } = ref.current;
      const modalBottomAlmostReached = scrollTop + clientHeight >= scrollHeight;
      if (modalBottomAlmostReached) {
        setIsModalBottomAlsmotReached(modalBottomAlmostReached);
      }
    };

    if (ref?.current) {
      ref.current.addEventListener('scroll', handleScroll);
    }
  };

  const notificationDisplayRef = useRef(null);
  const notificationDisplayMobileRef = useRef(null);

  useEffect(() => {
    if (notificationIsOpen) {
      infinityScrollNotification(notificationDisplayRef);
      infinityScrollNotification(notificationDisplayMobileRef);
    }
  }, [notificationIsOpen]);

  useEffect(() => {
    if (isModalBottomAlsmotReached && hasMore) {
      setNotificationLoading(true);
      getFeedPosts(userInfo.uId, keywords, orgs, [], lastCreationDate, limit).then((data) => {
        if (data?.result && !(data.result.length < limit)) {
          setIsModalBottomAlsmotReached(false);
          const lastItemIndex = data.result.length - 1;
          const lastDate = data.result[lastItemIndex].notification
            ? data.result[lastItemIndex].creationDate
            : data.result[lastItemIndex].publishDate;

          setLastCreationDate(lastDate);
          data.result.shift();
          setFeedPosts([...feedPosts, ...data.result]);
          setNotificationLoading(false);
        } else {
          setHasMore(false);
          setIsModalBottomAlsmotReached(false);
          setNotificationLoading(false);
        }
      });
    }
    // eslint-disable-next-line
  }, [isModalBottomAlsmotReached, userInfo]);

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawer = (v) => {
    setDrawerOpened(v);
  };

  const openAnonymousModal = () => {
    setDrawerOpened(false);
    setAnchorEl(null);
    setOpenState(true);
  };

  const handleLogout = async () => {
    trackEventMatomo('Menu', 'tocar', 'botao', 'sair');
    await logout();
    window.location.replace(window.location.origin);
  };

  const floatingMenu = () => {
    return (
      <List>
        {isAnonymous ? (
          <ListItem>
            <MenuItemList
              to="/validarAcesso"
              style={{
                // padding: 0,
                fontWeight: 500,
              }}
              onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'login')}
            >
              <SignIn size={20} weight="regular" />
              {t('drawer.begin_session')}
            </MenuItemList>
          </ListItem>
        ) : (
          <ListItem>
            <MenuItemList
              onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'meu perfil')}
              to="/meusDados"
            >
              <User size={20} weight="regular" />
              {t('drawer.profile')}
            </MenuItemList>
          </ListItem>
        )}
        <Divider />
        <ListItem>
          <LinkHtml
            href={'https://triibo.com.br/termos-de-uso-dos-servicos-triibo/'}
            target="new"
            onClick={() => {
              trackEventMatomo('Menu', 'tocar', 'botao', 'termos e políticas de uso');
            }}
          >
            <Files size={20} weight="regular" />
            {t('drawer.terms_and_policies')}
          </LinkHtml>
        </ListItem>

        <Divider />
        <ListItem>
          <MenuItemList
            to="/fale-conosco"
            onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'fale conosco')}
          >
            <Headset size={20} weight="regular" />
            {t('drawer.contact_us')}
          </MenuItemList>
        </ListItem>

        <Divider />
        {!isAnonymous && (
          <ListItem>
            <MenuItemList onClick={() => handleLogout()} to="#">
              <SignOut size={20} weight="regular" />
              {t('drawer.exit')}
            </MenuItemList>
          </ListItem>
        )}
      </List>
    );
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {floatingMenu()}
    </Menu>
  );
  const renderMenuMobile = (
    <MenuMobile $bg={infosSeuClube?.colorPrimary}>
      <li>
        <Link to="/" onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'Home App')}>
          <IconButton aria-label="home">
            <HouseLine
              size={30}
              weight={
                window.location.pathname === '' || window.location.pathname === '/'
                  ? 'fill'
                  : 'light'
              }
              color={colorText(colorPrimary)}
            />
          </IconButton>
        </Link>
      </li>
      <li>
        <Link
          onClick={() => {
            isAnonymous && openAnonymousModal();
            trackEventMatomo('Menu', 'tocar', 'botao', 'Minha Carteira');
          }}
          to={isAnonymous ? '#' : '/carteira'}
        >
          <IconButton aria-label="home">
            <Wallet
              size={30}
              weight={window.location.pathname.indexOf('/carteira') === 0 ? 'fill' : 'light'}
              color={colorText(colorPrimary)}
            />
          </IconButton>
        </Link>
      </li>
      <li>
        <Link
          onClick={() => {
            isAnonymous && openAnonymousModal();
            trackEventMatomo('Menu', 'tocar', 'botao', 'Caixa de Entrada');
          }}
          to={isAnonymous ? '#' : '/caixaDeEntrada'}
        >
          <IconButton aria-label="notificacoes">
            <ChatText
              size={30}
              weight={window.location.pathname.indexOf('/caixaDeEntrada') === 0 ? 'fill' : 'light'}
              color={colorText(colorPrimary)}
            />
          </IconButton>
        </Link>
      </li>

      <li>
        <IconButton aria-label="show more" onClick={() => handleDrawer(true)}>
          <MenuIcon size={30} color={colorText(colorPrimary)} />
        </IconButton>
      </li>
    </MenuMobile>
  );

  const renderDrawer = (
    <Drawer anchor="left" open={drawerOpened} onClose={() => handleDrawer(false)}>
      <div style={{ width: '70vw' }} role="presentation" onClick={() => handleDrawer(false)}>
        <DisplayFlex $bg="#FFF" content="flex-end" $aligncontent="center">
          <IconButton
            aria-label="voltar"
            style={{ color: colorText(colorPrimary) }}
            onClick={() => handleDrawer(false)}
          >
            <ChevronLeft style={{ color: '#000', fontSize: 35 }} />
          </IconButton>
        </DisplayFlex>
        {floatingMenu()}
      </div>
    </Drawer>
  );

  if (!authenticated || !infosSeuClube) {
    return (
      <div className="loading-login">
        <Loading />
      </div>
    );
  } else if (!infosSeuClube?.status) {
    return (
      <PageInactiveClube
        bgcolor={infosSeuClube?.colorPrimary}
        colorfont={infosSeuClube?.colorSecondary}
      >
        <h1>{t('drawer.inactive_clube')}</h1>
      </PageInactiveClube>
    );
  } else {
    return (
      <div
        style={{
          grow: {
            flexGrow: 1,
          },
        }}
      >
        {!isAnonymous && infosSeuClube && <NPSModal colorPrimary={infosSeuClube.colorPrimary} />}
        <LoginModal openState={openState} setOpenState={setOpenState} />
        <AppBar>
          <ContainerTopBar $bg={infosSeuClube ? infosSeuClube.colorPrimary : infosSeuClube}>
            <Grid container sx={{ maxWidth: '1500px', alignItems: 'center' }}>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                }}
              >
                <Link onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'logo')} to="/">
                  <img
                    src={cfac22('STORAGE_URL') + 'seuClube%2F' + infosSeuClube.logo + '?alt=media'}
                    alt="logo"
                    id="logo-club"
                    style={{
                      marginRight: 16,
                    }}
                  />
                </Link>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  gap: '40px',
                  justifyContent: 'center',
                }}
              >
                <Hidden smDown>
                  <Link onClick={() => trackEventMatomo('Menu', 'tocar', 'botao', 'home')} to="/">
                    <IconBtn>
                      <HouseLine
                        size={40}
                        weight={
                          window.location.pathname === '' || window.location.pathname === '/'
                            ? 'fill'
                            : 'light'
                        }
                        color={colorText(colorPrimary)}
                      />
                      <HoverBar $bg={infosSeuClube.colorSecondary} />
                    </IconBtn>
                  </Link>
                  <Link
                    onClick={() => {
                      isAnonymous && openAnonymousModal();
                      trackEventMatomo('Menu', 'tocar', 'botao', 'carteira');
                    }}
                    to={isAnonymous ? '#' : '/carteira'}
                  >
                    <IconBtn>
                      <Wallet
                        size={40}
                        weight={
                          window.location.pathname.indexOf('/carteira') === 0 ? 'fill' : 'light'
                        }
                        color={colorText(colorPrimary)}
                      />
                      <HoverBar $bg={infosSeuClube.colorSecondary} />
                    </IconBtn>
                  </Link>

                  <Link
                    onClick={() => {
                      isAnonymous && openAnonymousModal();
                      trackEventMatomo('Menu', 'tocar', 'botao', 'caixa de entrada');
                    }}
                    to={isAnonymous ? '#' : '/caixaDeEntrada'}
                  >
                    <IconBtn>
                      <ChatText
                        size={40}
                        weight={
                          window.location.pathname.indexOf('/caixaDeEntrada') === 0
                            ? 'fill'
                            : 'light'
                        }
                        color={colorText(colorPrimary)}
                      />
                      <HoverBar $bg={infosSeuClube.colorSecondary} />
                    </IconBtn>
                  </Link>
                </Hidden>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  justifyContent: 'right',
                  gap: '20px',
                }}
              >
                <Hidden smDown>
                  {userInfo && !userInfo.triiboId.includes('anonymous') && (
                    <S.NotificationContainerDesktop>
                      <NotificationDisplay
                        notificationLoading={notificationLoading}
                        notificationDisplayRef={notificationDisplayRef}
                        isOpen={notificationIsOpen}
                        promotionList={promotionList}
                        setPromotionList={setPromotionList}
                        feedPosts={feedPosts}
                        setFeedPosts={setFeedPosts}
                        colorPrimary={colorPrimary}
                        colorSecondary={colorSecondary}
                      />
                      <BellNotificationMenu
                        setNotificationIsOpen={setNotificationIsOpen}
                        notificationIsOpen={notificationIsOpen}
                        promotionList={promotionList}
                        feedPosts={feedPosts}
                        setFeedPosts={setFeedPosts}
                        handleNotificationCloser={handleNotificationCloser}
                        colorPrimary={colorPrimary}
                        colorSecondary={colorSecondary}
                      />
                    </S.NotificationContainerDesktop>
                  )}

                  <MenuWeb>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      color="inherit"
                      disableTouchRipple
                      style={{ background: 'transparent' }}
                      onClick={handleProfileMenuOpen}
                    >
                      <MenuIcon size={40} color={colorText(colorPrimary)} />
                    </IconButton>
                  </MenuWeb>
                </Hidden>
                {!isAnonymous && (
                  <Hidden smUp>
                    <PointAreaMobile>
                      <PointArea>
                        <span style={{ color: colorText(colorPrimary) }}>
                          {userInfo.aliasName}
                          <br />
                          {infosSeuClube.showScore &&
                            t('nav_bar.points', {
                              points: points,
                            })}
                        </span>
                        <S.NotificationContainerMobile>
                          <NotificationDisplay
                            notificationLoading={notificationLoading}
                            notificationDisplayRef={notificationDisplayMobileRef}
                            isOpen={notificationIsOpen}
                            promotionList={promotionList}
                            setPromotionList={setPromotionList}
                            feedPosts={feedPosts}
                            setFeedPosts={setFeedPosts}
                            colorPrimary={colorPrimary}
                            colorSecondary={colorSecondary}
                          />
                          <BellNotificationMenu
                            setNotificationIsOpen={setNotificationIsOpen}
                            notificationIsOpen={notificationIsOpen}
                            promotionList={promotionList}
                            feedPosts={feedPosts}
                            setFeedPosts={setFeedPosts}
                            handleNotificationCloser={handleNotificationCloser}
                            colorPrimary={colorPrimary}
                            colorSecondary={colorSecondary}
                          />
                        </S.NotificationContainerMobile>
                      </PointArea>
                    </PointAreaMobile>
                  </Hidden>
                )}
              </Grid>
            </Grid>
          </ContainerTopBar>
        </AppBar>

        {renderMenu}
        {renderMenuMobile}
        {renderDrawer}
        <main className="main">
          <UploadUserPhotos isOpen={isOpenModalUserPhoto} onRequestClose={closeModalUserPhoto} />
          {fullwidthPages.includes(window.location.pathname.replace(/\//g, '')) ? (
            <div className="center-column">
              <LoggedRoutes setOpenState={setOpenState} openState={openState} />
            </div>
          ) : (
            <div className="container">
              <Grid container style={{ paddingBottom: 10 }}>
                {/* GRID 1 */}
                <Grid item md={3}>
                  <LeftColumn
                    style={{
                      position: 'sticky',
                      top: '100px',
                    }}
                  >
                    <UserContainer $containerheight={isAnonymous}>
                      {isAnonymous ? (
                        <UserContentLoggedout>
                          <Link to="/validarAcesso">
                            <ButtonLogin
                              onClick={() =>
                                trackEventMatomo('Home', 'tocar', 'button', 'iniciar sessão')
                              }
                              $bg={infosSeuClube.colorPrimary}
                              style={{ color: colorText(infosSeuClube.colorPrimary) }}
                            >
                              {t('drawer.begin_session')}
                            </ButtonLogin>
                          </Link>
                        </UserContentLoggedout>
                      ) : (
                        <UserContent>
                          <h2>
                            {t('nav_bar.hello')}
                            <br />
                            <span>{!userInfo.aliasName ? userInfo.name : userInfo.aliasName}</span>
                          </h2>
                        </UserContent>
                      )}
                      {isAnonymous ? null : (
                        <>
                          {infosSeuClube.showScore === true ? (
                            <PointArea>
                              <Line />
                              <div>
                                {t('nav_bar.total_user_points_plural')}
                                <span>
                                  {t('nav_bar.points', {
                                    points: points,
                                  })}
                                </span>
                              </div>
                            </PointArea>
                          ) : null}
                        </>
                      )}
                    </UserContainer>
                    <LeftBanner>
                      <a
                        href={infosSeuClube.linkBannerLeft}
                        onClick={() =>
                          trackEventMatomo('Home', 'click', 'banner esquerdo', 'link externo')
                        }
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          src={
                            cfac22('STORAGE_URL') +
                            'seuClube%2F' +
                            infosSeuClube.bannerLeft +
                            '?alt=media'
                          }
                          alt="Banner da direita"
                        />
                      </a>
                    </LeftBanner>
                  </LeftColumn>
                </Grid>{' '}
                {/* column left */}
                {/* GRID 1 END */}
                {/* GRID 2 */}
                <Grid container item md={6}>
                  <CenterColumn>
                    <LoggedRoutes setOpenState={setOpenState} openState={openState} />
                  </CenterColumn>
                </Grid>
                {/* GRID 3  */}
                <Grid item md={3}>
                  <RightColumn
                    style={{
                      position: 'sticky',
                      top: '100px',
                      display: 'flex',
                      justifyContent: 'right',
                    }}
                  >
                    <a
                      onClick={() =>
                        trackEventMatomo('Home', 'click', 'banner direito', 'link externo')
                      }
                      href={infosSeuClube.linkBannerRight}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        src={
                          cfac22('STORAGE_URL') +
                          'seuClube%2F' +
                          infosSeuClube.bannerRight +
                          '?alt=media'
                        }
                        alt="Banner da direita"
                      />
                    </a>
                  </RightColumn>
                </Grid>
                {/* GRID 3 END */}
              </Grid>
            </div>
          )}

          {infosSeuClube.whatsapp?.active ? (
            <WhatsappButton cellphone={infosSeuClube.whatsapp.cellphone} />
          ) : null}
          <Footer />
        </main>
      </div>
    );
  }
}
