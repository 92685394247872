import { getLastUserInfo, getUrlVariables } from 'js/library/utils/helpers';
import React from 'react';
import { AllModules } from 'synoro-package/packages';

export default function LandingPage() {
  const urlVars = getUrlVariables();
  const userInfo = getLastUserInfo();

  return <AllModules id={urlVars.id} userInfo={userInfo} />;
}
