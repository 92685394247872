import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Typography,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  IconButton,
} from '@mui/material';
import QRCode from 'qrcode.react';
import { convertToDateDefault, formatDate, trackEventMatomo } from 'js/library/utils/helpers';
import placeholderItem from 'styles/assets/placeholder/placeholder.gif';
import consumedVoucher from 'styles/assets/Wallet/consumedVoucher.png';
import consumedVoucher_ES from 'styles/assets/Wallet/consumedVoucher_ES.png';
import { downloadImage } from 'js/library/services/StorageManager.js';
import { getLastUserInfo } from 'js/library/utils/helpers';

export default function Voucher() {
  const isAnonymous = getLastUserInfo().triiboId.indexOf('@anonymous-triibo,com,br') > 0;
  const verifyLanguage = localStorage.getItem('i18nextLng');

  const { t } = useTranslation();
  const location = useLocation();
  const promo = location.state;
  const vouchers = location.state.vouchers;
  const [thumbnail, setThumbnail] = useState(placeholderItem);

  const consumed = promo.state === 'finished' || promo.status === 'consumido';

  useEffect(() => {
    document.title = 'Detalhes da carteira';
  }, []);

  const downloadThumb = () => {
    if (promo.thumbnail !== undefined) {
      downloadImage('voucher', promo.thumbnail).then((img) => {
        setThumbnail(img);
      });
    }
  };

  if (isAnonymous) {
    return <Navigate to="/" />;
  } else {
    return (
      <div className="paper-container">
        <div>
          <Button
            style={{ cursor: 'default', backgroundColor: '#000000' }}
            disabled
            fullWidth={true}
            variant="contained"
          >
            <span
              style={{
                color: 'white',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              <Typography variant="h6">
                <span className="white-font">{consumed ? 'Cupons inativos' : 'Cupons Ativos'}</span>
              </Typography>
            </span>
          </Button>

          <div style={{ paddingTop: '20px', textAlign: 'center' }}>
            <img
              width="90%"
              className="img-radius"
              src={thumbnail}
              onLoad={() => downloadThumb()}
              alt="carteira"
            />

            <div style={{ padding: '15px' }}>
              <Typography variant="subtitle2">{promo.kind}</Typography>

              <Typography
                variant="h6"
                color="primary"
                style={{
                  fontWeight: 'bold',
                }}
              >
                {promo.detailsTitle || promo.title}
              </Typography>

              <Typography style={{ whiteSpace: 'pre-line' }} variant="subtitle2">
                {promo.detailsDescription || promo.description}
              </Typography>

              {promo.score ? (
                <Typography
                  variant="subtitle2"
                  style={{
                    fontWeight: 'bold',
                    paddingTop: '25px',
                  }}
                >
                  {t('wallet.total_user_coupons', {
                    coupons: promo.score,
                  })}
                </Typography>
              ) : null}

              {promo.type === 'store' || promo.type === 'promotion' ? (
                <div style={{ marginTop: '30px' }}>
                  {consumed ? (
                    <img
                      src={verifyLanguage === 'pt-BR' ? consumedVoucher : consumedVoucher_ES}
                      width="70%"
                      alt="cupom consumido"
                    />
                  ) : (
                    <QRCode
                      id="QRCode"
                      value={promo.key}
                      size={140}
                      bgColor={'#ffffff'}
                      fgColor={'#000000'}
                      level={'M'}
                    />
                  )}
                  <Typography variant="body1">{promo.key}</Typography>
                  <Typography variant="caption">
                    {consumed
                      ? t('wallet.coupon_was_consumed_on') +
                        convertToDateDefault(promo.consumedDate)
                      : t('wallet.for_redeem_your_coupon')}
                  </Typography>
                </div>
              ) : null}
            </div>
          </div>
        </div>

        {promo.consumptionType !== 'externalConsumption' ? (
          <div
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              paddingTop: '10px',
            }}
          >
            <List>
              {vouchers
                ? Object.keys(vouchers).map(function (item, i) {
                    return (
                      <ListItem divider key={item}>
                        <ListItemText
                          primary={
                            <div>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {Object.keys(promo.vouchers)[i]}
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                style={{
                                  fontWeight: 'bold',
                                }}
                              >
                                {formatDate(promo.vouchers[item].sentDate)}
                              </Typography>
                            </div>
                          }
                        />
                        <ListItemSecondaryAction>
                          <IconButton aria-label="Comments">
                            <Typography
                              variant="subtitle1"
                              style={{
                                fontWeight: 'bold',
                              }}
                            >
                              {promo.vouchers[item].value}
                            </Typography>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  }, this)
                : ''}
            </List>
          </div>
        ) : null}

        <div style={{ paddingBottom: '20px', textAlign: 'center' }}>
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bold',
            }}
            component={(props) => (
              <Link
                color="primary"
                onClick={() =>
                  trackEventMatomo('Voucher', 'tocar', 'botao', 'Voltar para a carteira')
                }
                to={{ pathname: '/carteira' }}
                {...props}
                style={{ textDecoration: 'none', fontWeight: '700' }}
              />
            )}
          >
            {t('wallet.back_to_wallet')}
          </Typography>
        </div>
      </div>
    );
  }
}

