import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Paper,
  Grid,
  Typography,
  Divider,
  CircularProgress,
  AppBar,
  Tabs,
  Tab,
} from '@mui/material';

import GoBack from 'js/containers/GoBack.js';
import BalanceGridActive from 'js/containers/Balance/BalanceGridActive';
import BalanceGridInactive from 'js/containers/Balance/BalanceGridInactive';
import { Navigation } from 'swiper/modules';

import {
  getLastUserInfo,
  formatDate,
  trackEventMatomoVisit,
  trackEventMatomo,
  getClubIdEnvironment,
} from 'js/library/utils/helpers';
import { getWallet_v1 } from 'js/library/utils/API/getWallet_v1.js';
import { getBalance_v1 } from 'js/library/utils/API/getBalance_v1.js';
import { getSeuClube } from 'js/library/utils/API/seuClube';

import { useTranslation } from 'react-i18next';

export default function Wallet() {
  const { t } = useTranslation();

  const userInfo = getLastUserInfo();
  const isAnonymous = userInfo.triiboId.indexOf('@anonymous-triibo,com,br') > 0;

  const infoSeuClube = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const [balance, setBalance] = useState(null);
  const [points, setPoints] = useState(0);
  const [hiddenExtract, setHiddenExtract] = useState(false);
  const [tab, setTab] = useState(0);
  const [activeCoupons, setActiveCoupons] = useState(null);
  const [inactiveCoupons, setInactiveCoupons] = useState(null);
  const [activeBP, setActiveBP] = useState(0);

  const configs = {
    activeBP: activeBP,
    modules: [Navigation],
    navigation: {
      nextEl: '.click-btn-next',
      prevEl: '.click-btn-prev',
    },
    breakpoints: {
      '@0.00': {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      '@0.75': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '@1.00': {
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },

    on: {
      breakpoint: (bp) => {
        if (activeBP !== bp.passedParams.breakpoints[bp.currentBreakpoint].slidesPerView) {
          setActiveBP(bp.passedParams.breakpoints[bp.currentBreakpoint].slidesPerView);
        }
      },
    },
  };

  useEffect(() => {
    trackEventMatomoVisit('Carteira');
    document.title = 'Carteira';

    if (!isAnonymous) {
      const clubeId = getClubIdEnvironment();
      if (clubeId) {
        getSeuClube(clubeId).then((dataClube) => {
          if (dataClube?.showScore) {
            setHiddenExtract(true);
          }
        });
      }

      getBalance_v1(userInfo.uId, userInfo.triiboId).then((balanceReceived) => {
        let balanceAux = [];

        Object.entries(balanceReceived)
          .reverse()
          .map((item, i) => {
            if (item[0] === 'consolidateBalance') {
              setPoints(item[1].total);
              return null;
            } else {
              const itemBalance = item[1];
              itemBalance.key = item[0];
              itemBalance.date = formatDate(item[1].dataEvento);
              return balanceAux.push(itemBalance);
            }
          });

        setBalance(balanceAux);
      });

      getWallet_v1(userInfo.uId).then((walletReceived) => {
        if (walletReceived != null) {
          const activeCouponsAux = [];
          const inactiveCouponsAux = [];

          if (walletReceived.coupons !== null) {
            Object.entries(walletReceived.coupons).forEach((coupon) => {
              const item = coupon[1];
              item.key = coupon[0];

              if (item.status === 'enviado' || item.state === 'ongoing') {
                activeCouponsAux.push(item);
              } else {
                inactiveCouponsAux.push(item);
              }
            });
          }

          setActiveCoupons(activeCouponsAux);
          setInactiveCoupons(inactiveCouponsAux);
        }
      });
    }
  }, [isAnonymous, userInfo]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  console.log(activeCoupons);

  if (isAnonymous) {
    return <Navigate to="" />;
  } else {
    return (
      <div className="paper-container">
        <div style={{ display: 'flex' }}>
          <GoBack />
          <Typography
            variant="h6"
            style={{
              fontWeight: 'bolder',
              width: '85%',
              textAlign: 'center',
            }}
          >
            {t('wallet.wallet')}
          </Typography>
        </div>
        <Divider color="secondary" style={{ margin: '10px 0px' }} />

        <Grid container style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Paper
              style={{
                padding: '8px',
                marginBottom: '30px',
                backgroundColor: '#FAFAFA',
              }}
            >
              {activeCoupons === null || inactiveCoupons === null ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress
                    style={{
                      padding: '150px 20px',
                      boxSizing: 'content-box',
                      color: infoSeuClube.colorPrimary,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <AppBar position="static" style={{ boxShadow: 'none' }}>
                    <Tabs
                      value={tab}
                      onChange={handleChange}
                      sx={{
                        padding: '0 12px',
                        background: '#fff',
                        '& button:hover': { background: '#dadada' },
                        '& button.Mui-selected': {
                          color: infoSeuClube.colorPrimary,
                        },
                      }}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: infoSeuClube.colorPrimary,
                        },
                      }}
                      centered={window.innerWidth < 600}
                      variant={window.innerWidth < 600 ? 'fullWidth' : 'standard'}
                    >
                      <Tab
                        onClick={() =>
                          trackEventMatomo('Carteira', 'tocar', 'botao', 'Cupons ativos')
                        }
                        style={{
                          textTransform: 'none',
                          color: '#000000',
                          fontWeight: 'bolder',
                          fontSize: 14,
                        }}
                        label={t('wallet.active_coupons')}
                      />
                      <Tab
                        onClick={() =>
                          trackEventMatomo('Carteira', 'tocar', 'botao', 'Cupons inativos')
                        }
                        style={{
                          textTransform: 'none',
                          color: '#000000',
                          fontWeight: 'bolder',
                          fontSize: 14,
                        }}
                        label={t('wallet.inactive_coupons')}
                      />
                    </Tabs>
                  </AppBar>

                  {tab === 0 ? (
                    activeCoupons.length === 0 ? (
                      <Typography
                        variant="h6"
                        style={{
                          paddingTop: '5px',
                        }}
                      >
                        <span style={{ color: '#4a4a4a' }}>
                          {t('wallet.dont_have_active_coupons')}
                        </span>
                      </Typography>
                    ) : (
                      <BalanceGridActive
                        coupons={activeCoupons}
                        configs={configs}
                        activeBP={activeBP}
                      />
                    )
                  ) : inactiveCoupons.length === 0 ? (
                    <Typography
                      variant="h6"
                      style={{
                        paddingTop: '5px',
                      }}
                    >
                      <span style={{ color: '#4a4a4a' }}>
                        {t('wallet.dont_have_inactive_coupons')}
                      </span>
                    </Typography>
                  ) : (
                    <BalanceGridInactive
                      coupons={inactiveCoupons}
                      configs={configs}
                      activeBP={activeBP}
                    />
                  )}
                </div>
              )}
            </Paper>
          </Grid>
        </Grid>

        {hiddenExtract === true && balance !== null ? (
          <Grid container style={{ width: '100%' }}>
            <Grid item md={12} sm={12} xs={12}>
              <Paper
                style={{
                  padding: '20px',
                  height: '393px',
                  overflowY: 'scroll',
                }}
              >
                <div>
                  <div style={{ float: 'left', width: '100%' }}>
                    <span
                      style={{
                        float: 'left',
                        textAlign: 'left',
                        width: '80%',
                      }}
                    >
                      <Typography
                        variant="h6"
                        color="black"
                        style={{
                          fontWeight: 'bold',
                        }}
                      >
                        {t('wallet.triibo_extract')}
                      </Typography>
                    </span>
                    <span
                      style={{
                        float: 'right',
                        textAlign: 'right',
                        width: '20%',
                      }}
                    >
                      <Typography variant="subtitle1" color="black">
                        <b>{points} pts</b>
                      </Typography>
                    </span>
                  </div>

                  {balance.map((item, i) => {
                    let showDate =
                      balance[i - 1] === undefined || balance[i - 1].dataEvento === undefined;

                    if (!showDate) {
                      const lastDate = balance[i - 1].date.split('/');
                      const currentDate = balance[i].date.split('/');

                      showDate = !(
                        lastDate[0] === currentDate[0] &&
                        lastDate[1] === currentDate[1] &&
                        lastDate[2] === currentDate[2]
                      );
                    }

                    return (
                      <div key={item.key}>
                        <Typography variant="subtitle1">
                          {t('wallet.day')} {item.date}
                        </Typography>

                        <div
                          style={{
                            float: 'left',
                            width: '100%',
                            borderBottom: '1px solid #333',
                          }}
                        >
                          <span
                            style={{
                              float: 'left',
                              textAlign: 'left',
                              width: '50%',
                            }}
                          >
                            <Typography variant="body1">{item.descricao}</Typography>
                          </span>
                          <span
                            style={{
                              float: 'right',
                              textAlign: 'right',
                              width: '50%',
                            }}
                          >
                            <Typography variant="body1">{item.valor} pts</Typography>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Paper>
            </Grid>
          </Grid>
        ) : null}
      </div>
    );
  }
}

