import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import 'styles/css/App.css';

//PowerBI para usuários
import Reports from 'js/components/ReportsBI/Reports';

//Menu
import Drawer from 'js/containers/Drawer/Drawer';
import { startMatomo } from 'js/library/utils/helpers';

export default function App() {
  useEffect(() => {
    startMatomo();
  }, []);
  return (
    <Routes>
      <Route exact path="/reports-bi" element={<Reports />} />

      <Route exact path="*" element={<Drawer />} />
    </Routes>
  );
}
