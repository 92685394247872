import React, { useEffect, useRef, useState } from 'react';
import NpsModalView from './NPSModalView';

import { getLastUserInfo } from 'js/library/utils/helpers';
import { fetchNpsQuestions, submitNpsForm, numbersNPS } from './NPSModel';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/bundle';

function NPSModal(props) {
  const [toggleModal, setToggleModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [questions, setQuestion] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmited, setIsSubmited] = useState(false);
  const [isCheckpostphone, setIsCheckpostphone] = useState(false);
  const swiperRef = useRef(null);
  const userInfo = getLastUserInfo();

  const closeModal = () => setToggleModal(false);

  const validateFields = (questions) =>
    !questions.some(({ required }, index) => {
      const currentAnswer = answers[index].text;
      return required && currentAnswer === '';
    });

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const requiredQuestions = validateFields(questions);
    if (requiredQuestions || isChecked) {
      const res = await submitNpsForm(userInfo, answers, isChecked);

      if (res?.success) {
        setIsChecked(true);
        setIsSubmited(true);
      }
    }
  };

  useEffect(() => {
    const setQuestions = async () => {
      const response = await fetchNpsQuestions(userInfo);
      if (response === 'Usuário não é elegivel para responder o formulário') {
        setToggleModal(false);
        return;
      }
      if (response?.questions?.length) {
        setIsCheckpostphone(response.rules.postphoneCheck);
        setQuestion(response.questions);
        const initialAnswers = response.questions.map(() => ({ text: '' }));
        setAnswers(initialAnswers);
        setToggleModal(true);
      }
    };

    setQuestions();
  }, [userInfo]);

  if (toggleModal)
    return (
      <NpsModalView
        colorPrimary={props.colorPrimary}
        isSubmited={isSubmited}
        swiperRef={swiperRef}
        toggleModal={toggleModal}
        isChecked={isChecked}
        questions={questions}
        numbersNPS={numbersNPS}
        setAnswers={setAnswers}
        closeModal={closeModal}
        handleCheckboxChange={() => setIsChecked(!isChecked)}
        handleFormSubmit={handleFormSubmit}
        isCheckpostphone={isCheckpostphone}
      />
    );

  return <></>;
}

export default NPSModal;
