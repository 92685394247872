import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import { messaging } from 'js/library/utils/firebaseUtils';
import { onMessage, getToken } from 'firebase/messaging';

import 'react-toastify/dist/ReactToastify.css';
import postToken from 'js/library/utils/API/postToken';

//area logada
import Home from 'js/components/Home/Home';
import { Store } from 'js/components/StorePlaces/Store';
import Places from 'js/components/StorePlaces/Places';
import StorePlacesLists from 'js/components/StorePlaces/StorePlacesLists';
import Wallet from 'js/components/Wallet/Wallet';
import Voucher from 'js/components/Wallet/Voucher';
import EditUser from 'js/components/EditUser';
import Inbox from 'js/components/Inbox/Inbox';
import HowToUse from 'js/components/HelpPages/HowToUse';
import About from 'js/components/HelpPages/About';
import TalkWithUs from 'js/components/HelpPages/TalkWithUs';

//icones
import alert from 'styles/assets/Messages/Alert.png';
import establishment from 'styles/assets/Messages/Establishment.png';
import news from 'styles/assets/Messages/News.png';
import promotion from 'styles/assets/Messages/Promotion.png';
import triibomania from 'styles/assets/Messages/Triibomania.png';
import provider from 'styles/assets/Messages/Provider.png';
import tribomania from 'styles/assets/Messages/Tribomania.png';
import logoTriibo from 'styles/assets/triibo-thumb.png';
import { debounce, trackEventMatomo } from 'js/library/utils/helpers';
import { AddressForm } from '../AddressForm/AddressForm';

//login
import ValidateAccess from 'js/components/LoginForms/ValidateAccess';
import ValidateCellphone from 'js/components/LoginForms/ValidateCellphone';
import CreateUser from 'js/components/LoginForms/CreateUser';

//tratamento e redirecionamento
import Entrance from 'js/components/Entrance/Entrance';

//Confirmação de dados do usuário
import DataConfirmation from 'js/components/DataConfirmation/DataConfirmation';

//Links do estabelecimento
import EstablishmentLinks from 'js/components/EstablishmentLinks';
import RegistrationUnavailable from 'js/components/RegistrationUnavailable';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import LandingPage from 'js/components/Campaign/LandingPage';

export const LoggedRoutes = (props) => {
  const { setOpenState } = props;

  const icons = {
    alert,
    establishment,
    news,
    promotion,
    triibomania,
    provider,
    tribomania,
  };

  useEffect(() => {
    // PERMISSÃO DE NOTIFICAÇÃO FIREBASE
    try {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          getToken(messaging, {
            vapidKey: cfac22('FIREBASE_PUBLIC_VAPID_KEY'),
          })
            .then((currentToken) => {
              if (currentToken) {
                sendToken(currentToken);

                // NOTIFICAÇÃO COM A GUIA ABERTA
                onMessage(messaging, (payload) => {
                  const typeIcon = payload.data?.type;
                  const { body, title } = payload.notification;
                  const notificationOptions = {
                    body,
                    icon: typeIcon ? `${icons[typeIcon]}` : logoTriibo,
                  };

                  return new Notification(title, notificationOptions);
                });
              }
            })
            .catch(() => {});
        }
      });
    } catch (error) {
      console.log(error);
    }
    // eslint-disable-next-line
  }, []);

  const getUserInfo = () => {
    const userInfoAuxiliar = localStorage.getItem('userInfoAuxiliar');
    const userForm = JSON.parse(localStorage.getItem('persist:userForm'));
    const codeInput = JSON.parse(localStorage.getItem('persist:codeInput'));

    const userFormTypeObj = userForm.userInfo;
    const codeInputTypeObj = codeInput.userInfo;

    const userInfo =
      userInfoAuxiliar !== undefined &&
      userInfoAuxiliar !== null &&
      typeof JSON.parse(userInfoAuxiliar) === 'object'
        ? JSON.parse(userInfoAuxiliar)
        : JSON.parse(codeInputTypeObj) === null
        ? JSON.parse(userFormTypeObj)
        : JSON.parse(codeInputTypeObj);

    return userInfo;
  };

  const sendToken = async (token) => {
    const { triiboId, uId } = getUserInfo();

    if (triiboId && uId) await postToken(uId, triiboId.replace(/[.]/gi, ','), token);
  };

  useEffect(() => {
    document.addEventListener(
      'scroll',
      debounce(function (event) {
        const titlePage = event.target.title;

        trackEventMatomo(titlePage, 'scroll', 'lista', titlePage.toLowerCase());
      }, 400)
    );
  }, []);

  return (
    <Routes>
      {/* <ToastContainer autoClose={4000} /> */}
      <Route exact path="/" element={<Home />} />
      <Route path="/ofertas/" element={<StorePlacesLists />} />
      <Route exact path="/carteira" element={<Wallet />} />
      <Route path="/carteira/detalhes" element={<Voucher />} />
      <Route exact path="/meusDados" element={<EditUser />} />
      <Route exact path="/caixaDeEntrada" element={<Inbox />} />
      <Route path="/oferta/" element={<Store setOpenState={setOpenState} />} />

      <Route path="/estabelecimento/" element={<Places setOpenState={setOpenState} />} />

      <Route path="/como-usar" element={<HowToUse />} />
      <Route path="/sobre" element={<About />} />
      <Route exact path="/fale-conosco" element={<TalkWithUs />} />
      <Route path="/endereco" element={<AddressForm />} />
      {/* Telas disconectas do home */}
      <Route exact path="/validarAcesso" element={<ValidateAccess />} />
      <Route exact path="/cadastroUsuario" element={<CreateUser />} />
      <Route exact path="/validarCelular" element={<ValidateCellphone />} />
      <Route exact path="/registration-unavailable" element={<RegistrationUnavailable />} />
      <Route exact path="/entrance" element={<Entrance />} />
      <Route exact path="/estabelecimento-links" element={<EstablishmentLinks />} />
      <Route exact path="/confirmar-dados" element={<DataConfirmation />} />
      <Route exact path="/landing-page" element={<LandingPage />} />
    </Routes>
  );
};

