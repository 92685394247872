import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from './authToken';

export function CheckUserPartnerCellphone(partnerId, cellPhone) {
  return new Promise(async (resolve, reject) => {
    authToken().then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/userPartner/checkUserPartnerCellphone`,
        headers: {
          Origin: `${cfac22('API_HOST_V2')}/`,
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          cellphone: cellPhone,
          partnerId: partnerId,
        },
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  });
}
