import axios from 'axios';

import cfac22 from 'js/library/utils/cfac22/cfac22';
import { findIndexInArray } from 'js/library/utils/helpers.js';
import { loginUser } from 'js/library/services/AuthenticationManager.js';
import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import { getUserPartner } from './apiGetUserPartner';

//buscando usuario p/ login
export function getUserInfo_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then(async (result) => {
            //console.log('RESULTADO DA API GET INFO ===>', result);
            const cellPhone =
              userInfo.contactList[
                findIndexInArray(userInfo.contactList, 'type', 'cellPhone')
              ].value;

            if (result.data.success.userInfo === false) {
              window.pathname = '/cadastroUsuario';

              return resolve({ cellPhone: cellPhone, userInfo: null });
            }

            if (result.data.success.userInfo) {
              const seuClubePersist = JSON.parse(
                sessionStorage.getItem('seuClubeInfos')
              );
              const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;
              getUserPartner(partnerId, result.data.success.userInfo.uId).then(
                async (res) => {
                  if (res) {
                    //logando user no firebase auth
                    localStorage.removeItem('userInfoAuxiliar');
                    await loginUser(
                      result.data.success.userInfo.triiboId.replace(
                        /[,]/gi,
                        '.'
                      ),
                      result.data.success.userInfo.passPhrase
                    );

                    return resolve({
                      cellPhone: cellPhone,
                      userInfo: result.data.success.userInfo,
                    });
                  } else {
                    window.pathname = '/cadastroUsuario';
                    return resolve({
                      cellPhone: cellPhone,
                      userInfo: result.data.success.userInfo,
                    });
                  }
                }
              );
            }
          })
          .catch((error) => {
            //console.log('ERROR GET USER INFO ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}

//busca simples
export function getUserInfoBasic_v1(queryPartnerAPI, userInfo) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((result) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        //TODO capturar sessionId e transactionId
        axios
          .post(cfac22('API_HOST_V1') + 'getUserInfo_v1', {
            triiboHeader: {
              apiToken: cfac22('apiToken'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: result,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            queryPartnerAPI,
            userInfo,
          })
          .then((result) => {
            //console.log('RESULTADO DA API GET INFO ===>', result);

            return resolve(result.data.success.userInfo);
          })
          .catch((error) => {
            console.log('ERROR GET USER INFO ===>', error);

            return reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        return reject(error);
      });
  });
}
