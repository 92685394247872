import React, { useContext, useEffect, useState } from 'react';
import * as S from './styles';

import { validateUserPartner } from 'js/library/utils/API/apiValidateUserPartner';
import {
  colorText,
  maskCEP,
  maskCPF,
  randomizeNumbersString,
  trackEventMatomo,
  trackEventMatomoVisit,
  getLastUserInfo,
} from 'js/library/utils/helpers';
import { useTranslation } from 'react-i18next';
import phoneMaskArray from 'js/components/LoginForms/phoneMaskArray.json';
import { useNavigate, Navigate } from 'react-router-dom';
import { SignUpContext } from 'js/context/SignUpContext';
import { toast } from 'react-toastify';
import phone from 'phone';
import { CircularProgress } from '@mui/material';
import { ContainerSendCode, RadioButton } from '../phoneInputStyle';
import { checkUserStatus } from 'js/library/services/AuthenticationManager.js';

export default function ValidateAccess() {
  const { t } = useTranslation();
  const [fieldInfos, setFieldInfos] = useState({});

  const [inputValue, setInputValue] = useState('');

  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [currentCountry, setCurrentCountry] = useState('');
  const [setFullMask] = useState('+55(99)99999-9999');
  const [cellphoneInput, setCellphoneInput] = useState('');
  const [loading, setLoading] = useState(false);

  const [mask, setMask] = useState('');

  const {
    formFields,
    setCellPhone,
    setValidateInfos,
    pathRedirect,
    setSendCodeType,
    sendCodeType,
    setAccessToken,
  } = useContext(SignUpContext);

  useEffect(() => {
    const userInfo = getLastUserInfo();
    let isAnonymous = userInfo !== null;
    isAnonymous = isAnonymous ? userInfo.triiboId?.indexOf('@anonymous-triibo,com,br') >= 0 : false;

    checkUserStatus().then(async (response) => {
      console.log('----', response, userInfo);
      if (response && !isAnonymous) {
        return navigate('/');
      }
    });
  }, []);

  const navigate = useNavigate();

  const seuClubePersist = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(',');
    setInputValue('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setFullMask(valuesArr[2]);
    setCurrentCountry(e.target.value);
  };

  const handleInputChange = (event, input) => {
    let value = event.target.value;

    if (input.fieldType === 'cellPhone') {
      return setCellphoneInput(value);
    }

    if (input.fieldType === 'cpf') {
      return setInputValue(maskCPF(value));
    }

    if (input.fieldType === 'cep') {
      return setInputValue(maskCEP(value));
    }

    return setInputValue(value);
  };

  const renderInput = () => {
    switch (fieldInfos.inputType) {
      case 'text':
      case 'email':
      case 'date':
        return fieldInfos.fieldType === 'cellPhone' ? (
          <S.ContainerInput>
            <S.BoxCellPhone>
              <S.Adornment>
                <select
                  style={{
                    fontFamily: 'Source Sans Pro, sans-serif',
                    flex: '1',
                    height: '100%',
                    border: 'none',
                    outline: 'none',
                    fontSize: '17px',
                    color: '#383838d6',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  value={currentCountry}
                  onChange={(e) => handleCountryChange(e)}
                >
                  {phoneMaskArray.map((country, index) => (
                    <option
                      style={{
                        fontFamily: 'Source Sans Pro, sans-serif',
                      }}
                      key={country.id}
                      value={[country.ddd, country.mask, country.fullMask]}
                    >
                      {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
              </S.Adornment>

              <S.Input
                mask={inputMask}
                value={cellphoneInput}
                placeholder={randomizeNumbersString(inputMask)}
                onChange={(e) => handleInputChange(e, fieldInfos)}
                id={fieldInfos.fieldName}
                name={fieldInfos.fieldName}
                type="tel"
              />
            </S.BoxCellPhone>
          </S.ContainerInput>
        ) : (
          <S.BoxCellPhone>
            <S.Input
              type={fieldInfos.inputType}
              mask={mask}
              value={inputValue}
              placeholder={mask}
              onChange={(e) => handleInputChange(e, fieldInfos)}
              id={fieldInfos.fieldName}
              name={fieldInfos.fieldName}
              required={fieldInfos.required}
            />
          </S.BoxCellPhone>
        );

      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let accessToken =
      fieldInfos.fieldType === 'cellPhone' ? inputValue : inputValue.replace(/\D/g, '');

    const partnerId = `${seuClubePersist.id}_${seuClubePersist.clubeId}`;

    const phoneValidateInfos = phone(accessToken);

    if (fieldInfos.fieldType === 'cellPhone' && !phoneValidateInfos.isValid) {
      setLoading(false);
      return toast.warn('Número inválido.');
    }

    if (fieldInfos.fieldType === 'cpf' && accessToken.length < 11) {
      setLoading(false);
      return toast.warn('Cpf inválido.');
    }

    validateUserPartner(accessToken, partnerId)
      .then((res) => {
        if (!res.isEnabled) {
          //mostrar que não tem acesso
          setLoading(false);
          return toast.warn('Você não possui acesso para entrar nesse clube.');
        }

        setValidateInfos(res);
        if (fieldInfos.fieldType === 'cellPhone') {
          setCellPhone(accessToken);
        } else {
          setCellPhone(res.cellPhone ? res.cellPhone : '');
        }
        setAccessToken(accessToken);

        if (res.isEnabled && !res.user) {
          return navigate('/cadastroUsuario');
        }

        if (res.isEnabled && res.user) {
          return navigate('/validarCelular');
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Ocorreu um erro, tente novamente mais tarde.');
        // console.log('erro: ', err);
      });
  };

  useEffect(() => {
    if (fieldInfos.fieldType === 'cellPhone') {
      setInputValue(currentDDD + cellphoneInput.replace(/\D/g, ''));
    }
  }, [fieldInfos, currentDDD, cellphoneInput]);

  useEffect(() => {
    if (formFields.length > 0) {
      const field = formFields.filter((field) => field.indexKey === true);
      setFieldInfos(field[0]);
    }
  }, [formFields]);

  useEffect(() => {
    if (fieldInfos) {
      // se o fieldType for igual a inputMask seta a mascara correta, se não usa o inputMask
      if (fieldInfos.fieldInfosType === fieldInfos.inputMask) {
        switch (fieldInfos.fieldType) {
          case 'cellPhone':
            setMask('(99) 99999-9999');
            break;
          case 'cpf':
            setMask('999.999.999-99');
            break;
          case 'cnpj':
            setMask('99.999.999/9999-99');
            break;
          case 'cep':
            setMask('99999-999');
            break;
          case 'date':
            setMask('99/99/9999');
            break;
          default:
            setMask('');
            break;
        }
      } else {
        setMask(fieldInfos.inputMask || '');
      }
    }
  }, [fieldInfos]);

  useEffect(() => {
    if (inputValue && fieldInfos.fieldType === 'cpf') {
      setInputValue(maskCPF(inputValue));
    }
  }, [inputValue, fieldInfos]);

  useEffect(() => {
    trackEventMatomoVisit('ValidarAcesso');
  }, []);

  if (pathRedirect === '/registration-unavailable') {
    return <Navigate to={pathRedirect} />;
  }

  return (
    <S.Container className="paper-container">
      <h1>{fieldInfos.label}</h1>

      <S.Form onSubmit={handleSubmit}>
        {renderInput()}
        {currentDDD === '+55' ? null : (
          <ContainerSendCode>
            <span>{t('general.botton-option-send-code')}</span>
            <span>
              <RadioButton
                type="button"
                activecolor={colorText(seuClubePersist.colorPrimary)}
                isactive={sendCodeType.sms === true}
                onClick={() =>
                  setSendCodeType({
                    ...sendCodeType,
                    sms: true,
                    whatsapp: false,
                  })
                }
              >
                SMS
              </RadioButton>
              <RadioButton
                type="button"
                activecolor={colorText(seuClubePersist.colorPrimary)}
                isactive={sendCodeType.whatsapp === true}
                onClick={() =>
                  setSendCodeType({
                    ...sendCodeType,
                    sms: false,
                    whatsapp: true,
                  })
                }
              >
                Whatsapp
              </RadioButton>
            </span>
          </ContainerSendCode>
        )}
        <S.Button
          onClick={() => trackEventMatomo('validarAcesso', 'tocar', 'button', 'Validar')}
          type="submit"
          $bgColor={seuClubePersist.colorPrimary}
          disabled={loading}
          style={{ marginTop: '10px', color: colorText(seuClubePersist.colorPrimary) }}
        >
          {loading ? (
            <CircularProgress
              size={20}
              style={{ margin: '0 auto', color: colorText(seuClubePersist.colorPrimary) }}
            />
          ) : (
            'Validar'
          )}
        </S.Button>
      </S.Form>
    </S.Container>
  );
}
