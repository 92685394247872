import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export function getSeuClube(clubeId, uId) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .get(
          cfac22('API_HOST_V2') + '/seuClube/getSeuClube?clubeId=' + clubeId,
          {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then((result) => {
          // console.log("RESULTADO SEU CLUBE ==>", result.data.result);
          resolve(result.data.result);
          sessionStorage.setItem(
            'seuClubeInfos',
            JSON.stringify(result.data.result)
          );
        })
        .catch((error) => {
          // console.log('ERRO SEU CLUBE ==>', error);
          return reject(error.response.data);
        });
    });
  });
}

export function setSeuClube(clubeId, url) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') + '/seuClube/postSeuClube',
          {
            clubeId,
            url,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO POST SEU CLUBE ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR POST SEU CLUBE ==>", error);
          return reject(error);
        });
    });
  });
}

export function updateSeuClube(newClubeId, payload, uid) {
  console.log(newClubeId, payload);
  return new Promise((resolve, reject) => {
    authToken(uid).then((token) => {
      axios

        .put(
          cfac22('API_HOST_V2') + '/seuClube/updateSeuClube',
          {
            newClubeId,
            payload,
          },
          { headers: { authorization: `Bearer ${token}` } }
        )
        .then((result) => {
          // console.log("RESULTADO UPDATE SEU CLUBE ==>", result);
          resolve(result);
        })
        .catch((error) => {
          // console.log("ERROR UPDATE SEU CLUBE ==>", error);
          return reject(error);
        });
    });
  });
}
