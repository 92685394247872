import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import pt_BR from './languages/pt-BR.json';
import es from './languages/es.json';

const languageNavigator = navigator.language.replace('-', '_');

const resources = {
  es: {
    translation: es,
  },
  pt_BR: {
    translation: pt_BR,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    compatibilityJSON: 'v3',
    resources,
    detection: {
      order: ['navigator', 'localStorage'],
    },
    fallbackLng: languageNavigator === 'es' ? 'es' : 'pt_BR',

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

