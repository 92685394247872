import React, { useEffect, useState } from 'react';
import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import * as S from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Controller } from 'swiper/modules';
import { colorText } from 'js/library/utils/helpers';
import Input from '../DesignSystem/Input';

const NpsModalView = ({
  colorPrimary,
  isSubmited,
  setAnswers,
  swiperRef,
  isChecked,
  numbersNPS,
  closeModal,
  handleCheckboxChange,
  handleFormSubmit,
  questions,
  isCheckpostphone,
}) => {
  const [widthScreen, setWidthScreen] = useState(window.innerWidth);
  const [currentRef, setCurrentRef] = useState({});

  useEffect(() => {
    const handleResize = () => {
      setWidthScreen(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleItemClick = (index, type, number) => {
    setAnswers((prevState) => {
      const newAnswers = [...prevState];
      newAnswers[index] = { [type]: number };

      return newAnswers;
    });
    currentRef.slideTo(number);
  };

  const handleChangeTextField = (e, index, type) => {
    setAnswers((prevState) => {
      const newAnswers = [...prevState];
      newAnswers[index] = { [type]: e.target.value.trim() };

      return newAnswers;
    });
  };

  const renderRatingItems = (index, type) =>
    numbersNPS.map(({ number, color }) => (
      <SwiperSlide onClick={() => handleItemClick(index, type, number)} key={type + number}>
        <S.NpsItemRating color={color}>
          <p>{number}</p>
        </S.NpsItemRating>
      </SwiperSlide>
    ));

  const renderQuestions = questions.map(({ question, type, required }, index) => {
    const id = `slide${index}`;
    return type === 'rating' ? (
      <div key={id}>
        <Typography
          component="p"
          style={{ fontSize: widthScreen > 320 ? '18px' : '10px', marginTop: '20px' }}
        >
          {question}
        </Typography>

        <S.SlideContainer>
          <div className={`swiper-button-prev prevCustom-${id} slide-arrow`}></div>
          <S.NpsRatingContainer>
            <Swiper
              modules={[Controller]}
              ref={swiperRef}
              spaceBetween={50}
              effect={'coverflow'}
              loop={false}
              grabCursor={true}
              centeredSlides={true}
              slidesPerView={'auto'}
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1.5,
              }}
              onSlideChange={(swiper) => {
                setCurrentRef(swiper);
                setAnswers((prevState) => {
                  const newAnswers = [...prevState];
                  newAnswers[index] = { [type]: swiper.activeIndex };
                  return newAnswers;
                });
              }}
              initialSlide={10}
              pagination={{
                el: `.prevCustom-${id}`,
                clickable: true,
              }}
              navigation={{
                nextEl: `.nextCustom-${id}`,
                prevEl: `.prevCustom-${id}`,
                clickable: true,
              }}
              className={`swiper_container prevCustom-${id}`}
            >
              {renderRatingItems(index, type)}
            </Swiper>
          </S.NpsRatingContainer>
          <div className={`swiper-button-next nextCustom-${id} slide-arrow`}></div>
        </S.SlideContainer>
      </div>
    ) : (
      <>
        <Typography component="p" style={{ fontSize: '18px', marginTop: '30px' }}>
          {question}
        </Typography>

        <Input
          type={'text'}
          handleChange={(e) => handleChangeTextField(e, index, type)}
          required={required}
        />
      </>
    );
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        zIndex: '9999999',
        background: '#000000aa',
        height: '100%',
        width: '100%',
        top: '0',
      }}
    >
      <form
        onSubmit={isSubmited ? closeModal : handleFormSubmit}
        style={{ width: '100%', maxWidth: 800, background: 'white' }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            background: '#ff7507',
            padding: 10,
          }}
        >
          <Typography variant="h6" style={{ color: 'white' }}>
            Pesquisa de Satisfação
          </Typography>

          <Button variant="contained" color="secondary" onClick={closeModal}>
            X
          </Button>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            padding: widthScreen > 320 ? 40 : 10,
            textAlign: 'center',
            height: isChecked ? 'unset' : '80vh',
            overflowY: 'scroll',
          }}
        >
          {!isChecked && <div style={{ marginBottom: '1rem' }}>{renderQuestions}</div>}

          <S.NpsModalButtonsContainer>
            {isSubmited ? (
              <Typography variant="body2" style={{ fontSize: widthScreen > 320 ? '16px' : '10px' }}>
                Obrigado por responder a pesquisa!
              </Typography>
            ) : (
              isCheckpostphone && (
                <FormControlLabel
                  style={{ marginLeft: '5px' }}
                  control={
                    <Checkbox
                      checked={isChecked}
                      color="primary"
                      onChange={handleCheckboxChange}
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  }
                  label={
                    <Typography
                      variant="body2"
                      style={{ fontSize: widthScreen > 320 ? '16px' : '10px' }}
                    >
                      Não mostrar pelos próximos 7 dias
                    </Typography>
                  }
                />
              )
            )}

            <Button
              type="submit"
              variant="contained"
              style={{
                backgroundColor: colorPrimary,
                color: colorText(colorPrimary),
                fontWeight: 600,
              }}
            >
              {isSubmited ? 'Fechar' : 'Enviar'}
            </Button>
          </S.NpsModalButtonsContainer>
        </Grid>
      </form>
    </div>
  );
};

export default NpsModalView;
