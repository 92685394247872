import React from 'react';
import { Link } from 'react-router-dom';

import { Typography, Grid, Paper } from '@mui/material';

import { downloadImage } from 'js/library/services/StorageManager.js';
import placeholder from 'styles/assets/placeholder/placeholder.gif';
import { trackEventMatomoElementId } from 'js/library/utils/helpers';

export const Product = (data) => {
  const [downloadedImage, setDownloadedImage] = React.useState(null);

  const id = data.data.id;

  data = data.data[data.data.type];
  data.id = id;

  function downloadImageOnLoad() {
    if (data.thumbnail !== undefined && downloadedImage === null) {
      data.thumbnail = data.thumbnail.replace('triibomania-premios/', '');

      const folder = data.type === 'product' ? 'triibomania-premios' : 'promocao';

      downloadImage(folder, data.thumbnail)
        .then((downloaded) => {
          setDownloadedImage(downloaded);
        })
        .catch((error) => {
          return error;
        });
    }
  }

  return (
    <Paper
      key={id}
      elevation={3}
      style={{ margin: '15px 2px', padding: '20px', borderRadius: '16px' }}
    >
      <Grid className="container-card-offer">
        <Grid item sm={5} md={5}>
          <Link
            onClick={() => trackEventMatomoElementId('ListaDePromocao', 'tocar', 'card', id)}
            to={{ pathname: '/oferta/', search: '?id=' + id, state: data }}
            style={{ textDecoration: 'none' }}
          >
            <img
              width="100%"
              alt="promo"
              onLoad={() => downloadImageOnLoad()}
              className="img-offer"
              src={downloadedImage === null ? placeholder : downloadedImage}
            />
          </Link>
        </Grid>
        <Grid item sm={5} md={7} lg={7}>
          <Link
            onClick={() => trackEventMatomoElementId('ListaDePromocao', 'tocar', 'card', id)}
            to={{ pathname: '/oferta/', search: '?id=' + id, state: data }}
            style={{ textDecoration: 'none', lineHeight: 1 }}
          >
            <Typography variant="subtitle1" color="primary" style={{ color: '#000' }}>
              {data.establishmentName}
            </Typography>
            <Typography
              gutterBottom
              variant="h6"
              color="primary"
              style={{ fontWeight: 'bolder', lineHeight: 1, color: '#000' }}
            >
              <i>{data.title}</i>
            </Typography>
            <Typography
              className="limit-text"
              variant="caption"
              style={{
                color: '#000',
                whiteSpace: 'pre-line',
              }}
            >
              {data.description}
            </Typography>

            <Typography
              className="limit-text"
              variant="caption"
              style={{
                color: '#000',
                fontWeight: 'bold',
                marginTop: '20px',
              }}
            >
              {/* {data.cost} pts */}
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Product;

