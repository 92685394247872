import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function getRatings(uId, establishmentId) {
  return new Promise((resolve, reject) => {
    authToken(uId)
      .then((token) => {
        axios
          .get(
            `${cfac22(
              'API_HOST_V2'
            )}/establishments/${establishmentId}/ratings`,
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            //console.log("RESULT_RATINGS: ", result);
            resolve(result.data);
          })
          .catch((error) => {
            // console.log("ERROR_RATINGS: ", error);
            return reject(error);
          });
      })
      .catch((error) => {
        // console.log("ERROR_TOKE:", error);
        reject(error);
      });
  });
}
