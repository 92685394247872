import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import GoBack from 'js/containers/GoBack.js';
import { Grid, Typography } from '@mui/material';
import { SignUpContext } from 'js/context/SignUpContext';

import { trackEventMatomoVisit } from 'js/library/utils/helpers.js';
import { CellphoneIcon, EmailIcon } from '../DesignSystem/Icons/IconsMenu';

export default function TalkWithUs() {
  const { t } = useTranslation();
  const { infosSeuClube } = useContext(SignUpContext);
  useEffect(() => {
    document.title = 'Fale conosco';
    trackEventMatomoVisit('FaleConosco');
  }, []);

  return (
    <div className="paper-container">
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20 }}>
        <GoBack />
        <Typography
          variant="h6"
          sx={{ fontWeight: 'bolder', color: '#707070', fontSize: { xs: 16, md: 20 } }}
        >
          {t('contact_us.contact_us')}
        </Typography>
      </div>

      <Grid container sx={{ textAlign: 'center' }}>
        <Grid item xs={12}>
          <CellphoneIcon color={infosSeuClube.colorPrimary} />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Typography variant="caption">Nosso telefone</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            (11) 94496-5799
          </Typography>
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '40px' }}>
          <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
            {t('contact_us.opening_hours')}
          </Typography>
          <Typography variant="body2">{t('contact_us.hours')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <EmailIcon color={infosSeuClube.colorPrimary} />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Typography variant="caption">Nosso Email</Typography>
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            contato@triibo.com.br
          </Typography>
        </Grid>
      </Grid>

      {/* TROCAR */}
    </div>
  );
}
