import axios from 'axios';
import cfac22 from 'js/library/utils/cfac22/cfac22';
import authToken from 'js/library/utils/API/authToken';

export default function getFeedPosts(
  uId,
  keywords,
  orgs,
  relationships,
  dateLimit,
  limit
) {
  return new Promise((resolve, reject) => {
    authToken(uId).then((token) => {
      axios
        .post(
          `${cfac22('API_HOST_V2')}/feedPosts`,
          {
            keywords,
            orgs,
            relationships,
            dateLimit,
            limit,
          },
          {
            headers: {
              authorization: `Bearer ${token}`,
              contentType: 'application/json',
            },
          }
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
