import React, { useEffect } from 'react';
import BalanceItem from 'js/containers/Balance/BalanceItem';

import Swiper from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/bundle';

export const BalanceGridActive = ({ coupons, configs, activeBP }) => {
  useEffect(() => {
    new Swiper('.swiper', configs);
  }, [activeBP, coupons, configs]);

  return (
    <div style={{ position: 'relative', margin: '0px 30px' }}>
      <div className={'swiper'}>
        <div className="swiper-wrapper" style={{ minHeight: '280px', height: '280px' }}>
          {coupons.map((item) => {
            return <BalanceItem voucher={item} key={item.key} />;
          })}
        </div>
      </div>
      {activeBP < coupons.length && (
        <>
          <div className={'click-btn-prev swiper-button-prev swiper-button-prev-outside'}></div>
          <div className={'click-btn-next swiper-button-next swiper-button-next-outside'}></div>
        </>
      )}
    </div>
  );
};
export default BalanceGridActive;
