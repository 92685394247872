import styled from 'styled-components';

const infoClub = JSON.parse(sessionStorage.getItem('seuClubeInfos'));

export const ContainerSendCode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  span:first-of-type {
    font-size: 1rem !important;
    font-weight: 700 !important;
    margin: 1.2rem 0 0 !important;
  }

  span:last-of-type {
    display: flex;
    flex-direction: row;
  }
`;

const colorsClub = {
  primary: infoClub ? infoClub.colorPrimary : '#000',
};

export const RadioButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
  border-radius: 7px;
  border: 0;
  background-color: ${(props) =>
    props.isactive ? colorsClub[props.activecolor] : '#AAA'};
  color: #fff;
  font-size: 16px;
  margin: 1rem 1rem 0 1rem;
  cursor: pointer;
`;
